const { detect } = require('detect-browser')

const generateHtml = name => `
  You're currently running ${name}. Please ONLY USE Google Chrome. Any issues, contact <a href="mailto:support@auto.works">support@auto.works</a>
`

const browserToName = browser => {
  if (browser.name == 'ie') {
    return `Internet Explorer ${browser.version}`
  }

  const name = browser.name[0].toUpperCase() + browser.name.slice(1)

  return `${name} ${browser.version}`
}

const browser = detect()
const aclRoles = window.localStorage.aclRoles

if (aclRoles && !aclRoles.includes('root') && browser) {
  if (browser.name !== 'chrome') {
    const name = browserToName(browser)
    const content = generateHtml(name)
    $('.js-browser-banner').show()
    $('.js-browser-banner').html(content)
  }
}
